import { useTranslation } from 'next-i18next'

import cn from 'classnames'

import { ADVANTAGES_ICONS } from '@/utils/constants/data'
import { ROBOTO_BOLD } from '@/utils/constants/themes'

import { type AdvantageSectionProps } from '../types'

const aos = [
  'fade-right-down',
  'fade-left-down',
  'fade-right-up',
  'fade-left-up',
]

const AdvantageSection = ({ data }: AdvantageSectionProps) => {
  const { t } = useTranslation()

  return (
    <div className="page-padding relative flex w-full flex-col items-center gap-y-16 bg-gradient-to-t from-white/0 to-primary/10 py-16 dark:to-primary/[0.15] lg:justify-center lg:gap-x-8 lg:gap-y-24 lg:py-32 xl:gap-x-36">
      <div className="flex w-full justify-center" data-aos="fade-down">
        <h2
          className={cn(
            'h2 max-w-2xl text-center dark:text-primary',
            ROBOTO_BOLD.className,
          )}
        >
          {t('home:adv_title')}
        </h2>
      </div>

      <div className="grid w-full max-w-5xl gap-4 md:grid-cols-2">
        {data.map(({ id, desc, title }, i) => {
          const Icon = ADVANTAGES_ICONS[i]

          return (
            <div
              key={id}
              className="my-transition hover:shadow-primary-md dark:hover:shadow-primary-md-dark group flex w-full flex-col gap-y-4 rounded-2xl px-8 py-12 hover:bg-white dark:hover:bg-dark sm:flex-row sm:gap-x-6"
              data-aos={aos[i]}
              data-aos-delay="300"
            >
              <div className="my-transition flex h-max w-max items-center justify-center rounded-full border border-primary p-4 text-primary-text group-hover:border-accent group-hover:text-accent">
                <Icon size={40} />
              </div>
              <div className="flex w-full flex-col gap-y-3">
                <h3 className={cn('text-xl', ROBOTO_BOLD.className)}>
                  {title}
                </h3>
                <div className="my-transition h-1 w-12 bg-primary group-hover:bg-accent" />
                <p>{desc}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdvantageSection
